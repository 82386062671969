 /* jshint ignore:start */
(function($){                                         
 
  //Navigation Menu Slider
  $('#nav-expander').on('click',function(e){
      e.preventDefault();
      $('body').toggleClass('nav-expanded');
  });
  $('#nav-close').on('click',function(e){
      e.preventDefault();
      $('body').removeClass('nav-expanded');
  });

  // Initialize navgoco with default options
  $(".main-menu").navgoco({
      caret: '<span class="caret"></span>',
      accordion: false,
      openClass: 'open',
      save: true,
      cookie: {
          name: 'navgoco',
          expires: false,
          path: '/'
      },
      slide: {
          duration: 300,
          easing: 'swing'
      }
  });
 

//Navigation Scroll

var text = $(".header").text();

function a() {

  function diff(min, max, factor) {
    return min+(max-min)*factor;
  }

  function diffInt(min, max, factor) {
    return parseInt(diff(min, max, factor));
  }

  function factor(min, max, actual) {
    return (actual-min)/(max-min);
  }

  function limit(min, max, actual) {
    return (max-actual > min) ? max-actual : min;
  }

   var scrollPos = $(this).scrollTop(),
      navMax = 185,
      navMin = 85,
      lineHeight = 20,
      navHeight = limit(navMin, navMax, scrollPos),
      factor = factor(navMin, navMax, navHeight),
      navPadding = (navHeight - lineHeight) / 2,
      fontSize = diff(18, 48, factor),
      r = diffInt(100, 6, factor),
      g = diffInt(0, 131, factor),
      b = diffInt(50, 201, factor),
      pos = diff(440, 0, factor),
      bgpos = diff(560, 130, factor),
      navopacity = diff(0.9, 0, factor*2),
      navw = diff(420, 0, factor),
      showbtn = (navw === 0) ? "show" : "hide";

$(".navbar-fixed-top").css({
    "min-height": navHeight+"px",
      "background-color": "rgba(228, 0, 69, "+navopacity+")",
       "font-size": fontSize+"px",
  });
}

if($(window).width()>767){
  a();
  $(window).scroll(function(){
    a();
  });
}
})(jQuery);
/* jshint ignore:end */
