/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

 // Use this variable to set up the common and page specific functions. If you
 // rename this variable, you will also need to rename the namespace below.
 var Sage = {
   // All pages
   'common': {
     init: function() {
          // Reference Ipad Video
          $('.second-wrap .nav-tabs a').on('show.bs.tab', function(e){
            let target = $(e.target).data('video');

            $('[data-rel="'+ target +'"]').addClass('show').siblings().removeClass('show');
          });

          $('.case-section.panel-group .panel-title a').append('<i class="fa fa-chevron-down" aria-hidden="true"></i>');

          $(document).on('click', '.embed-overlay', function(){
            $(this).siblings('.embed-responsive-item').get(0).play();
            $(this).remove();
          });

          // Ladebalken
          $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            e.target // newly activated tab
            e.relatedTarget // previous active tab
          });

          window.onload = function() {

    var bar = document.getElementById("bar"),
    fallback = document.getElementById("fallback"),
    loaded = 0;

    var load = function() {
        loaded += 10;
        bar.value = loaded;

        /* The below will be visible if the progress tag is not supported */
        $(fallback).empty().append("HTML5 progress tag not supported: " + loaded + "% loaded");

        if(loaded === 100) {
            clearInterval(dummyLoad);
        }
    };

    var dummyLoad = setInterval(function() {
        load();
    } ,40);
};

         /*//Primary Menü//*/
         var $orginalMenu = $('.menu__primary'),
             $targetMenu = $('.menu__wrap');

         // Hier legen wir dass Level 1 an. Später füllen wir das mit den ersten clones [1]
         $targetMenu.append('<ul data-menu="main" class="menu__level"></ul>');

         $.each($orginalMenu.find('.nav > li'), function(key, value) {

             // Clone (E1) vom Hauptpunkt erzeugen
             var cloneLi1 = $(this).clone();

             // Haben wir Kinder?
             var hasChildren = ($(this).hasClass('menu-item-has-children')) ? true : false;

             // Clone Ebene 1 Säubern
             cloneLi1.find('ul').remove();

             // Irgendwelcher Krams für das Javascript Menü was wir als vorlage nutzen (am Clone (E1))
             cloneLi1.addClass('menu__item').find('a').addClass('menu__link');

             if(hasChildren) {
                  cloneLi1.find('a').attr('data-submenu', 'submenu-'+key).attr('href', '');
             }

             // Clone (E1) am Ziel einfügen   [1]
             $('[data-menu="main"]').append(cloneLi1);

             // Haben wir Kinder im Submenü
             if(hasChildren) {
                 // Hier wollen wir pro submenü eine liste, in die wir dann die unterpunkte binden.
                 $targetMenu.append('<ul data-menu="submenu-'+key+'" class="menu__level"></ul>');

                 // Gleicher Spaß für die "Kinder" von dem aktuellen Punkt
                 $.each($(this).find('li'), function(key2, value2) {

                     // Gleiches spiel wie die ebene drüber
                     var cloneLi2 = $(this).clone();

                     // Clone Ebene 2 säubern
                     cloneLi2.find('ul').remove();

                     // weil wir keine weiteren kinder haben, brauchen wir ein paar parameter nicht mehr
                     cloneLi2.addClass('menu__item').find('a').addClass('menu__link');

                     $('[data-menu="submenu-'+key+'"]').append(cloneLi2);

                 });
             }

         });

         var menuEl = document.getElementById('ml-menu');

         var mlmenu = new MLMenu(menuEl, {
             // breadcrumbsCtrl : true, // show breadcrumbs
             // initialBreadcrumb : 'all', // initial breadcrumb text
             backCtrl : false // show back button
             // itemsDelayInterval : 60, // delay between each menu item sliding animation
         });

         if(window.location.href.indexOf("#kontaktform") > -1) {
             $('#mail').modal('show');
         }

        $('.ga-optout').on('click', function() {
          gaOptout();
        });

     },
     finalize: function() {
       // JavaScript to be fired on all pages, after page specific JS is fired
     }
   },
   // Home page
   'home': {
     init: function() {
       /*Maps*/

      //  var locations = [
      //    {
      //      name: 'Communicators GmbH & Co. KG',
      //      addr: 'Dorotheenstraße 84, 22301 Hamburg',
      //      lat: 53.585399,
      //      lng: 10.008321,
      //    },

      //    ];

      //  $("#mapWrapper").tqMap(locations);

           // JavaScript to be fired on the about us page

           var selectedCategory;

var $grid = $('.grid').isotope({
  itemSelector: '.grid-item',
  getSortData: {
    selectedCategory: function( itemElem ) {
      return $( itemElem ).hasClass( selectedCategory ) ? 0 : 1;
    }
  }
});

// layout Isotope after each image loads
$grid.imagesLoaded().progress( function() {
  $grid.isotope('layout');
});

var $items = $('.grid').find('.grid-item');

$('.sort-button-group').on( 'click', '.button', function() {
  // set selectedCategory
  selectedCategory = $( this ).attr('data-category');
  if ( selectedCategory === 'all' ) {
    $grid.isotope({
      sortBy: 'original-order'
    });
    // restore all items to full opacity
    $items.css({
      opacity: 1
    });
    return;
  }
  // change opacity for selected/unselected items
  var selectedClass = '.' + selectedCategory;
  $items.filter( selectedClass ).css({
    opacity: 1
  });
  $items.not( selectedClass ).css({
    opacity: 0.25
  });

  // update sort data now that selectedCategory has changed
  $grid.isotope('updateSortData');
  $grid.isotope({ sortBy: 'selectedCategory' });
});

  // change is-checked class on buttons
$('.button-group').each( function( i, buttonGroup ) {
  var $buttonGroup = $( buttonGroup );
  $buttonGroup.on( 'click', 'button', function() {
    $buttonGroup.find('.is-checked').removeClass('is-checked');
    $( this ).addClass('is-checked');
  });
});

     },
  //    finalize: function() {
  //      // JavaScript to be fired on the home page, after the init JS
  //    }
  //  },
  //  'kontakt': {
  //    init: function() {
  //      /*Maps*/

  //      var locations = [
  //        {
  //          name: 'Communicators GmbH & Co. KG',
  //          addr: 'Dorotheenstraße 84, 22301 Hamburg',
  //          lat: 53.585399,
  //          lng: 10.008321,
  //        },

  //        ];

  //      $("#mapWrapper").tqMap(locations);

  //    },
     finalize: function() {
       // JavaScript to be fired on the home page, after the init JS
     }
   },
   // About us page, note the change from about-us to about_us.
   'team': {
     init: function() {
       // JavaScript to be fired on the about us page

           var selectedCategory;

var $grid = $('.grid').isotope({
  itemSelector: '.grid-item',
  getSortData: {
    selectedCategory: function( itemElem ) {
      return $( itemElem ).hasClass( selectedCategory ) ? 0 : 1;
    }
  }
});

// layout Isotope after each image loads
$grid.imagesLoaded().progress(function() {
  $grid.isotope('layout');
});

var $items = $('.grid').find('.grid-item');

$('.sort-button-group').on( 'click', '.button', function() {
  // set selectedCategory
  selectedCategory = $( this ).attr('data-category');
  if ( selectedCategory === 'all' ) {
    $grid.isotope({
      sortBy: 'original-order'
    });
    // restore all items to full opacity
    $items.css({
      opacity: 1
    });
    return;
  }
  // change opacity for selected/unselected items
  var selectedClass = '.' + selectedCategory;
  $items.filter( selectedClass ).css({
    opacity: 1
  });
  $items.not( selectedClass ).css({
    opacity: 0.25
  });

  // update sort data now that selectedCategory has changed
  $grid.isotope('updateSortData');
  $grid.isotope({ sortBy: 'selectedCategory' });
});

  // change is-checked class on buttons
$('.button-group').each( function( i, buttonGroup ) {
  var $buttonGroup = $( buttonGroup );
  $buttonGroup.on( 'click', 'button', function() {
    $buttonGroup.find('.is-checked').removeClass('is-checked');
    $( this ).addClass('is-checked');
  });
});

     }
   }
 };

 // The routing fires all common scripts, followed by the page specific scripts.
 // Add additional events for more control over timing e.g. a finalize event
 var UTIL = {
   fire: function(func, funcname, args) {
     var fire;
     var namespace = Sage;
     funcname = (funcname === undefined) ? 'init' : funcname;
     fire = func !== '';
     fire = fire && namespace[func];
     fire = fire && typeof namespace[func][funcname] === 'function';

     if (fire) {
       namespace[func][funcname](args);
     }
   },
   loadEvents: function() {
     // Fire common init JS
     UTIL.fire('common');

     // Fire page-specific init JS, and then finalize JS
     $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
       UTIL.fire(classnm);
       UTIL.fire(classnm, 'finalize');
     });

     // Fire common finalize JS
     UTIL.fire('common', 'finalize');
   }
 };

 // Load Events
 $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
