

/* jshint ignore:start */






jQuery.noConflict();
   (function ($) {
  

   $('.ipad-video').on('click', function(){
      $(this).addClass('test');
   });


 

   })(jQuery);



/* jshint ignore:end */

